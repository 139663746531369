@charset "utf-8";
@import "_mixin.scss";
@import "_config.scss";

.foot_contact{
  background:url(../img/top_06.jpg) no-repeat center center;
  background-size:cover;
  background-attachment: fixed;
  position: relative;
  z-index: 2;
  width: 100%;
  @include pad_100
    @include media(767px) {
      background:url(../img/top_06_sp.jpg) no-repeat center center;
      background-size:cover;
      background-attachment: inherit;
  }
    .foot_contact_in{
      position: relative;
      z-index: 3;
      width: 80%;
      max-width: 700px;
      background-color: rgba(255,255,255,0.9);
      padding: 60px;
      box-sizing: border-box;
      margin: 0 auto;
      @include media(767px) {
        width: 95%;
        max-width: 700px;
        padding: 60px 10px;
      }
  }
  text-align: center;
  .ttl{
    letter-spacing: 5px;
    font-weight: bold;
    line-height: 1;
    @include fz_30
      }
  p{
    margin:20px auto 40px;
    span{
      margin-top:30px;
      display: block;
      @include fz_14
    }
  }
}

.sitemap_wrap{
  position: relative;
  z-index: 2;
  background: #fff;
  @include pad_100
  .sitemap{
    margin: 0 auto;
    @include media(767px) {
      width: 90%;
    }
    a{
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
    a:hover{
      color:$c2;
    }
    .big{
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 4px;
      line-height: 2;
    }
    .min{
      font-size: 14px;
      line-height: 2;
    }
    ul{
      @include media(1040px) {
        width: 50%;
        margin-bottom: 40px;
      }
      @include media(767px) {
        width: 100%;
      }
    }
  }
}
.footer{
  background:$c1;
  padding:80px 0 40px;
  position: relative;
  z-index: 2;
  .foot_logo{
    max-width: 150px;
    margin: 0 auto 60px;
    &:hover{
      opacity: 0.8;
    }
  }
  .foot_text{
    text-align: center;
    padding-left:10px;
    box-sizing: border-box;
    @include fz_12
  }
  .copy{
    text-align: center;
    margin:60px auto 0;
    @include fz_12
  }
}