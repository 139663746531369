@charset "utf-8";
@import "_mixin.scss";

$c1: #F1EFEF;
$c2: #FF8F22;
$c3: #F7F6EB;
$c4: #333;
$c5: #ddd;
$c6: #f7f7f7;
$c7: #e5e5e5;

$base_font_color: #2E2E2E;
$link_color: #2E2E2E;

$google-color: #C53727;
$facebook-color: #3B5998;
$twitter-color: #00aced;
$line-color: #5ae628;
$instagram-color: #3f729b;
$tumblr-color: #34526f;