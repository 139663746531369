@charset "utf-8";
@import "_mixin.scss";
@import "_config.scss";

.sitettl{
  position: fixed;
  top: 45vh;
  left: -70px;
  z-index: 100000;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  letter-spacing: 4px;
  @include fz_12
    @include media(767px) {
      left: 10px;
      top: 23px;
      transform: rotate(0deg);
      font-size: 12px;
      letter-spacing: 2px;
  }
}
.icon_f,
.icon_t,
.icon_i,
.icon_f_in,
.icon_i_in,
.icon_t_in{
  font-size:12px;
  text-align: center;
  width:26px;
  height: 26px;
  line-height: 26px;
  color:#fff !important;
  background:#333;
  border-radius: 50%;
  &:hover{
    opacity: 0.8;
  }
}
.icon_i{
  position:fixed;
  top:calc(90vh - 32px);
  right:10px;
  z-index: 100000;
  @include media(767px) {
    line-height: 28px;
    display: none;
    top:calc(83vh + 38px);
    right:10px;
  }
}
.icon_f{
  position:fixed;
  top:90vh;
  right:10px;
  z-index: 100000;
  @include media(767px) {
    line-height: 28px;
    display: none;
    top:83vh;
    right:10px;
  }
}
.icon_t{
  position:fixed;
  top:calc(90vh + 32px);
  right:10px;
  z-index: 100000;
  @include media(767px) {
    line-height: 28px;
    display: none;
    top:calc(83vh + 38px);
    right:10px;
  }
}
.icon_i_in{
  position:absolute;
  top:calc(90vh - 32px);
  right:10px;
  z-index: 100000;
  line-height: 28px;
}
.icon_f_in{
  position:absolute;
  top:90vh;
  right:10px;
  z-index: 100000;
  line-height: 28px;
  @include media(767px) {
    bottom:100px;
    right:10px;
  }
}
.icon_t_in{
  position:absolute;
  top:calc(90vh + 32px);
  right:10px;
  z-index: 100000;
  line-height: 28px;
  @include media(767px) {
    bottom:40px;
    right:10px;
  }
}
.t_in,
.i_in,
.f_in{
  li{
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }
  span{
    margin-left: 0 !important;
  }
}
.i_in{
  margin-top: 40px;
}

/*-----------------------------------------------
--nav
-----------------------------------------------*/

.nav{
  position: fixed;
  top:5vh;
  right: 0;
  z-index: 100000;
  @include media(1039px) {
    display: none;
  }
  ul{
    width: max-content;
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    li{
      background:$c1;
      padding:30px 25px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      line-height: 1.2;
      &.nav_con{
        background:#333;
        color: #fff;
        a{
          color: #fff;
        }
      }
      @include fz_16
        span{
          @include fz_10
        }
      }
    }
  }
.btn_menu{
  display: none;
}
.button {
  display: inline-block;
  outline: none;
}
.button::before,
.button::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: '';
}
.button,
.button::before,
.button::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.button {
  position: relative;
  z-index: 2;
  background-color: #fff;
  overflow: hidden;
}
.button:hover {
  color: #fff;
  a{
    color: #fff;
  }
}
.button.nav_con:hover {
  color: $link_color;
  a{
    color: $link_color;
  }
  input{
    color: $link_color !important;
  }
}
.button::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(.5);
  transform: scale(.5);
}
.button:hover::after {
  background: #333;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.button.nav_con:hover::after {
  background: $c1;
}

#navigation,
.hamburger{
  display: none;
}
@include media(1039px) {
  /*   ボタンタグ設定   */
  .btn_menu{
    display: block;
  }
  .button:hover::after {
    background: none;
  }
  .button.nav_con:hover::after {
    background:none;
  }
  .nav{
    button {
      display: block;
      background: none;
      border: none;
      padding: 0;
      width: 42px;
      color: #333;
      letter-spacing: 0.1em;
      cursor: pointer;
      position: fixed;
      top: 7px;
      right: 20px;
      z-index: 100000;
      text-align: center;
      outline: none;
      font-size: 10px;
    }
    /*   ハンバーガーボタン3本線   */
    button span.bar {
      display: block;
      height: 2px;
      background-color: #333;
      margin: 10px 0;
      transition: all 0.2s;
      transform-origin: 0 0;
    }
    button .close {
      letter-spacing: 0.08em;
      display: none;
    }
    button .menu {
      display: block;
    }
    /*  メニューアクティブ時  */
    button.active span.bar {
      width: 49px;
    }
    button.active .bar1 {
      transform: rotate(30deg);
    }
    button.active .bar2 {
      opacity: 0;
    }
    button.active .bar3 {
      transform: rotate(-30deg);
    }
    button.active .menu {
      display: none;
    }
    button.active .close {
      display: block;
    }
  }
  
  /*  メニュー  */
  @keyframes show{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  .head_nav {
    width: 100%;
    height: 100%;
    //transition:2s all ease-in-out 0.5s;
    //transform: translate(312px);
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    background-color: #FFF;
    animation: show 0.5s ease-in-out 0.5s;
  }
  /*  メニューアクティブ時  */
  .head_nav.open {
    //transform: translate(0);
    display: block;
    animation: show 0.5s ease-in-out 0.5s;
  }
  /*　以下、個別にレイアウト調整　*/
  #navigation{
    display: block;
    .navigation_inner{
      background:url(../img/bg_spnav.jpg) no-repeat center center;
      background-size: cover;
    }
    ul{
      padding: 0 0 0 10%;
      text-align: left;
      width:100%;
      li{
        width: 100%;
        padding: 0;
        background: none;
        margin-bottom: 30px;
        font-size:20px;
        color: $link_color;
        span{
          margin-left:30px;
        }
        &.nav_con{
          background: none;
          a{
            color: $link_color;
          }
        }
        .pc{
          display: none;
        }
      }
    }
  }
  /*----------ハンバーガーアイコン----------*/
  .hamburger {
    display: block;
    position: fixed;
    top:20px;
    right:10px;
    display: block;
    width: 25px;
    height: 20px;
    z-index: 99999;
    box-sizing: border-box;
  }
  .hamburger  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $c2;
    margin: 0 auto;
    cursor: pointer;
  }
  .hamburger  span:nth-of-type(1) {
    top: 0;
  }
  .hamburger  span:nth-of-type(2) {
    top: 9px;
  }
  .hamburger  span:nth-of-type(3) {
    bottom: 0;
  }
  


  .hamburger  span:nth-of-type(1) {
    -webkit-animation: menu-ber01 .75s forwards;
    animation: menu-ber01 .75s forwards;
  }
  .hamburger  span:nth-of-type(2) {
    transition: all .25s .25s;
    opacity: 1;
  }
  .hamburger  span:nth-of-type(3) {
    -webkit-animation: menu-ber02 .75s forwards;
    animation: menu-ber02 .75s forwards;
  }

  .active span:nth-of-type(1) {
    -webkit-animation: active-menu-ber01 .75s forwards;
    animation: active-menu-ber01 .75s forwards;
  }
  .active span:nth-of-type(2) {
    opacity: 0;
  }
  .active span:nth-of-type(3) {
    -webkit-animation: active-menu-ber03 .75s forwards;
    animation: active-menu-ber03 .75s forwards;
  }

  @-webkit-keyframes menu-ber01 {
    0% {
      -webkit-transform: translateY(8px) rotate(45deg);
    }
    50% {
      -webkit-transform: translateY(8px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(0) rotate(0);
    }
  }


  @keyframes menu-ber01 {
    0% {
      transform: translateY(8px) rotate(45deg);
    }
    50% {
      transform: translateY(8px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }


  @-webkit-keyframes menu-ber02 {
    0% {
      -webkit-transform: translateY(-8px) rotate(-45deg);
    }
    50% {
      -webkit-transform: translateY(-8px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(0) rotate(0);
    }
  }


  @keyframes menu-ber02 {
    0% {
      transform: translateY(-8px) rotate(-45deg);
    }
    50% {
      transform: translateY(-8px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }


  @-webkit-keyframes active-menu-ber01 {
    0% {
      -webkit-transform: translateY(0) rotate(0);
    }
    50% {
      -webkit-transform: translateY(10px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(10px) rotate(45deg);
    }
  }


  @keyframes active-menu-ber01 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(10px) rotate(0);
    }
    100% {
      transform: translateY(10px) rotate(45deg);
    }
  }


  @-webkit-keyframes active-menu-ber03 {
    0% {
      -webkit-transform: translateY(0) rotate(0);
    }
    50% {
      -webkit-transform: translateY(-8px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(-8px) rotate(-45deg);
    }
  }


  @keyframes active-menu-ber03 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-8px) rotate(0);
    }
    100% {
      transform: translateY(-8px) rotate(-45deg);
    }
  }


  /*----------ナビゲーション----------*/
  #navigation{
    position: fixed;
    display: none;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 99998;
  }
  #navigation .navigation_inner{
    display: table;
    width: 100%;
    height: 100%;
  }
  #navigation .navigation_inner .navigation_menu{
    display: table-cell;
    vertical-align: middle;
  }
  #navigation .navigation_inner .navigation_menu .navigation_item{
    width: 300px;
    height: 40px;
    margin: 16px auto 0 auto;
  }
  #navigation .navigation_inner .navigation_menu .navigation_item:first-child{
    margin-top: 0;
  }
  #navigation .navigation_inner .navigation_menu .navigation_item span{
    font-size: 12px;
  }
  #navigation .navigation_inner .navigation_menu .navigation_item a{
    display: block;
    position: relative;
    text-decoration: none;
    font-size: 2.4rem;
    color: #333;
    line-height: 40px;
    text-align: center;
  }
}
@include media(767px) {
.nav{
  ul{
    padding: 40vw 0 0 20vw;
    }
  }
}