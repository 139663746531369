@charset "utf-8";
@import "_mixin.scss";
@import "_config.scss";

/*-----------------------------------------------
--layout
-----------------------------------------------*/

.l_1{
  max-width: 1040px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
  @include media(767px) {
    width: 95%;
  }
}
.l_2{
  max-width: 1040px;
  width:100%;
  padding-right: 5%;
  padding-left: 5%;
  text-align: left;
  box-sizing: border-box;
}
.l_3{
  max-width: 1040px;
  width:100%;
  padding-right: 2.5%;
  padding-left: 2.5%;
  text-align: left;
  box-sizing: border-box;
}
.l_4{
  width:90%;
  margin: 0 auto;
}
.l_5{
  max-width: 1400px;
  width: 85%;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
  @include media(767px) {
    width: 95%;
  }
}

/*-----------------------------------------------
--段落用（レスポンシブ）
-----------------------------------------------*/

.pc{
  display: block;
  @include media(767px) {display: none;}
}
.tab{
  display: block;
  @include media(1040px) {display: block;}
  @include media(767px) {display: none;}
}
.sp{
  display: none;
  @include media(767px) {display: block;}
}
.spmin{
  display: none;
  @include media(450px) {display: block;}
}

/*-----------------------------------------------
--ホバーアクション（透過）
-----------------------------------------------*/

a{
  display: block;
}
a.org{
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
a.org:hover{
  color:$c2;
}

/*-----------------------------------------------
--画面ローディング
-----------------------------------------------*/


body {
  animation: fadeIn 2s ease 0s 1 normal;
  -webkit-animation: fadeIn 2s ease 0s 1 normal;
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

/*-----------------------------------------------
--フロート
-----------------------------------------------*/

.left{
  float:left;
}
.right{
  float:right;
}
.clearfix:after{
  content: "."; 
  display: block; 
  height: 0; 
  font-size:0;	
  clear: both; 
  visibility:hidden;
}

/*-----------------------------------------------
--Flex
-----------------------------------------------*/

.flex {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:stretch;
  @include media(767px) {
    justify-content:space-around;
  }
}
.flex2 {
  display:flex;
  flex-direction: row-reverse;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:stretch;
  @include media(767px) {
    justify-content:space-around;
  }
}
.flex3 {
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:stretch;
  align-items: center;
  @include media(767px) {
    justify-content:space-around;
  }
}

/*-----------------------------------------------
--Fonts
-----------------------------------------------*/

body {
  line-height: 1.6;
  font-size: 16px;
  @include media(767px) {
    font-size: 14px;
  }
  word-wrap : break-word;
  overflow-wrap : break-word;/*折り返しを綺麗にする*/
  font-family:Avenir, Verdana, Arial, "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Roboto, Meiryo, sans-serif;
  font-weight: 500;
  text-rendering: optimizeLegibility;/*アンチエイリアスなどフォント見やすくする*/
  color: $base_font_color;
  text-align: justify;/*均等に配置する*/
}

/*----游ゴシック__fontfamily---*/
/*body {
font-family:Avenir, Verdana, Arial, "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Roboto, Meiryo, sans-serif;
font-weight: 500;
}*/

a, a:link, a:active, a:visited {text-decoration: none;outline: none;color:$link_color}
a:hover, a:active {outline: 0;}

/*-----------------------------------------------
--fontfamily
-----------------------------------------------*/
.serif {
  font-family: 'Times New Roman', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', '小塚明朝 ProN', 'メイリオ', Meiryo, serif;
}
.eng{
  font-family: 'Josefin Slab', serif;
}
.lato{
  font-family: 'Lato', sans-serif;
}

/*-----------------------------------------------
--fontsize
-----------------------------------------------*/

.fz_36 {
  font-size: 36px;
  @include media(767px) {font-size: 30px;}
  @include media(600px) {font-size: 24px;}
}
.fz_30 {
  font-size: 30px;
  @include media(767px) {font-size: 24px;}
  @include media(600px) {font-size: 20px;}
}
.fz_28 {
  font-size: 27px;
  @include media(767px) {font-size: 23px;}
  @include media(600px) {font-size: 19px;}
}
.fz_24 {
  font-size: 24px;
  @include media(767px) {font-size: 20px;}
  @include media(600px) {font-size: 18px;}
}
.fz_22 {
  font-size: 22px;
  @include media(767px) {font-size: 20px;}
  @include media(600px) {font-size: 16px;}
}
.fz_20 {
  font-size: 20px;
  @include media(767px) {font-size: 18px;}
  @include media(600px) {font-size: 15px;}
}
.fz_18 {
  font-size: 18px;
  @include media(767px) {font-size: 16px;}
  @include media(600px) {font-size: 14px;}
}
.fz_16 {
  font-size: 16px;
  @include media(767px) {font-size: 14px;}
}
.fz_15 {
  font-size: 15px;
  @include media(767px) {font-size: 14px;}
}
.fz_14 {
  font-size: 14px;
  @include media(767px) {font-size: 13px;}
}
.fz_13 {
  font-size: 13px;
  @include media(600px) {font-size: 12px;}
}
.fz_12 {
  font-size: 12px;
  @include media(600px) {font-size: 10px;}
}