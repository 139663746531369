@charset "utf-8";
@import "_mixin.scss";
@import "_config.scss";

.pagination{
  text-align: right;
  margin-bottom:15px;
  span,
  a{
    display: inline-block;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
  a:hover{
    color:$c2;
  }
}
a.page-numbers,
.pagination .current{
  padding:5px 8px;
  margin:0 2px;
  text-decoration: none;
  @include fz_12
}
.pagination .current{
  opacity: 0.8;
}
