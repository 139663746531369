// -- /* 20150219 */ --
@mixin media($size){
	@media screen and (max-width : $size){
		@content;
	}
}
@mixin min_media($size){
	@media screen and (min-width : $size){
		@content;
	}
}

// -- /* 2014 */ --

@mixin input-placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}

// -- /* 2018 ozeki */ --

@mixin fz_40{
  font-size: 40px;
  @include media(767px) {
    font-size: 35px;
  }
  @include media(500px) {
    font-size: 30px;
  }
}
@mixin fz_38{
  font-size: 38px;
  @include media(767px) {
    font-size: 33px;
  }
  @include media(500px) {
    font-size: 28px;
  }
}
@mixin fz_36{
  font-size: 36px;
  @include media(767px) {
    font-size: 31px;
  }
  @include media(500px) {
    font-size: 26px;
  }
}
@mixin fz_34{
  font-size: 34px;
  @include media(767px) {
    font-size: 29px;
  }
  @include media(500px) {
    font-size: 24px;
  }
}
@mixin fz_32{
  font-size: 32px;
  @include media(767px) {
    font-size: 27px;
  }
  @include media(500px) {
    font-size: 22px;
  }
}
@mixin fz_30{
  font-size: 30px;
  @include media(767px) {
    font-size: 25px;
  }
  @include media(500px) {
    font-size: 20px;
  }
}
@mixin fz_28{
  font-size: 28px;
  @include media(767px) {
    font-size: 23px;
  }
}
@mixin fz_26{
  font-size: 26px;
  @include media(767px) {
    font-size: 22px;
  }
}
@mixin fz_24{
  font-size: 24px;
  @include media(767px) {
    font-size: 21px;
  }
}
@mixin fz_22{
  font-size: 22px;
  @include media(767px) {
    font-size: 20px;
  }
}
@mixin fz_20{
  font-size: 20px;
  @include media(767px) {
    font-size: 18px;
  }
}
@mixin fz_19{
  font-size: 19px;
  @include media(767px) {
    font-size: 17px;
  }
}
@mixin fz_18{
  font-size: 18px;
  @include media(767px) {
    font-size: 16px;
  }
}
@mixin fz_17{
  font-size: 17px;
  @include media(767px) {
    font-size: 15px;
  }
}
@mixin fz_16{
  font-size: 16px;
  @include media(767px) {
    font-size: 14px;
  }
}
@mixin fz_15{
  font-size: 15px;
  @include media(767px) {
    font-size: 13px;
  }
}
@mixin fz_14{
  font-size: 14px;
  @include media(767px) {
    font-size: 12px;
  }
}
@mixin fz_13{
  font-size: 13px;
  @include media(767px) {
    font-size: 11px;
  }
}
@mixin fz_12{
  font-size: 12px;
  @include media(767px) {
    font-size: 10px;
  }
}
@mixin fz_11{
  font-size: 11px;
}
@mixin fz_10{
  font-size:10px;
}


// 上下左右中央配置
@mixin center{
  text-align: center;
  position: relative;
  top:50%;
  -webkit-transform:translateY(-50%);
  transform:translateY(-50%);
}

//リンクカラー生成

@mixin link_color($color){
  color:$color;
  &:hover, &:active, &:focus{
    color: lighten($color,20%);
  }
}

@mixin pad_120{
  padding:120px 0;
  @include media(1040px) {
    padding:100px 0;
  }
  @include media(767px) {
    padding:80px 0;
  }
}
@mixin pad_100{
  padding:100px 0;
  @include media(1040px) {
    padding:80px 0;
  }
  @include media(767px) {
    padding:60px 0;
  }
}
@mixin pad_80{
  padding:80px 0;
  @include media(1040px) {
    padding:70px 0;
  }
  @include media(767px) {
    padding:60px 0;
  }
}
@mixin pad_60{
  padding:60px 0;
  @include media(1040px) {
    padding:50px 0;
  }
  @include media(767px) {
    padding:40px 0;
  }
}
@mixin pad_40{
  padding:40px 0;
  @include media(1040px) {
    padding:30px 0;
  }
  @include media(767px) {
    padding:20px 0;
  }
}
@mixin pad_30{
  padding:30px 0;
  @include media(1040px) {
    padding:25px 0;
  }
  @include media(767px) {
    padding:15px 0;
  }
}
@mixin pad_20{
  padding:20px 0;
}
@mixin pad_10{
  padding:10px 0;
}

@mixin pad_a_120{
  padding:120px;
  box-sizing: border-box;
  @include media(1040px) {
    padding:100px;
  }
  @include media(767px) {
    padding:80px;
  }
}
@mixin pad_a_100{
  padding:100px;
  box-sizing: border-box;
  @include media(1040px) {
    padding:80px;
  }
  @include media(767px) {
    padding:60px;
  }
}
@mixin pad_a_80{
  padding:80px;
  box-sizing: border-box;
  @include media(1040px) {
    padding:70px;
  }
  @include media(767px) {
    padding:60px;
  }
}
@mixin pad_a_60{
  padding:60px;
  box-sizing: border-box;
  @include media(1040px) {
    padding:50px;
  }
  @include media(767px) {
    padding:40px;
  }
}
@mixin pad_a_40{
  padding:40px;
  box-sizing: border-box;
  @include media(1040px) {
    padding:30px;
  }
  @include media(767px) {
    padding:20px;
  }
}
@mixin pad_a_30{
  padding:30px;
  box-sizing: border-box;
  @include media(1040px) {
    padding:25px;
  }
  @include media(767px) {
    padding:15px;
  }
}
@mixin pad_a_20{
  padding:20px;
  box-sizing: border-box;
}
@mixin pad_a_10{
  padding:10px;
  box-sizing: border-box;
}