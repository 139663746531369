@charset "utf-8";
@import "_mixin.scss";

.contact{
  background:$c3;
  padding:60px;
  box-sizing: border-box;
  margin:100px auto;
  @include media(1040px) {
    margin:80px 0;
  }
  @include media(767px) {
    margin:60px 0;
  }
  h2{
    margin-bottom:40px;
    text-align: center;
  }
  .des{
    margin-bottom:20px;
    text-align: center;
  }

  .cont_wrap{
    font-size:14px;
  }
  .cont_wrap .ttl{
    width: 100%;
    padding:20px 0 10px;
    font-weight: bold;
  }
  .cont_wrap dl{
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-flex-direction: row; /* Safari */
    flex-direction:         row;
    -webkit-flex-wrap: wrap; /* Safari */
    flex-wrap:         wrap;
    -webkit-justify-content: space-between; /* Safari */
    justify-content:         space-between;
    align-items: stretch;
  }
  .cont_wrap dt{
    width:30%;
    padding:20px 10px;
    box-sizing: border-box;
  }
  .cont_wrap dd{
    width:70%;
    padding:20px 10px;
    box-sizing: border-box;
  }
  .cont_wrap dt span{
    color:#ff0000;
    padding-left:10px;
    font-size: 12px;
    box-sizing: border-box;
  }
  .cont_wrap input[type="text"]{
    width:100%;
    max-width:500px;
    padding:10px 10px;
    box-sizing: border-box;
    border:1px solid #bbb;
  }
  .cont_wrap textarea{
    width:100%;
    max-width:500px;
    padding:10px 10px;
    box-sizing: border-box;
    border:1px solid #bbb;
  }
  .cont_wrap input[type="tel"]{
    width:100%;
    max-width:300px;
    padding:10px 10px;
    box-sizing: border-box;
    border:1px solid #bbb;
  }
  .cont_wrap input[type="email"]{
    width:100%;
    max-width:300px;
    padding:10px 10px;
    box-sizing: border-box;
    border:1px solid #bbb;
  }
  .cont_wrap .wpcf7-list-item{
    margin-left:20px;
  }
  .cont_wrap .wpcf7-list-item.first{
    margin-left:0px;
  }
  .cont_wrap .p_pri{
    box-sizing: border-box;
    border:1px solid #aaa;
    padding:15px;
    text-align: center;
  }
  .cont_wrap .p_pri .brsp{
    display: none;
  }
  .cont_wrap .p_pri .p_pri_btn{
    margin:10px auto 0;
  }
  .cont_wrap .submit{
    box-sizing: border-box;
    width: 200px;
    height: 60px;
    background:#333;
    padding-left: 78px;
    line-height: 60px;
    margin: 40px 0 0 38%;
    cursor: pointer;
  }
  .cont_wrap .submit input{
    background:none;
    border: none;
    font-size:16px;
    margin: 0 auto;
    color:#fff;
  }
}

@media screen and (max-width:767px){

  .contact{
    padding:40px 20px;
    margin:80px auto 40px;

    .cont_wrap dt{
      width:100%;
      padding:20px 10px 10px;
      box-sizing: border-box;
    }
    .cont_wrap dd{
      width:100%;
      padding:0px 10px 20px;
      box-sizing: border-box;
      border-top:none;
    }
    .cont_wrap .f_l{
      border-bottom:none;
    }

    .cont_wrap .p_pri{
      box-sizing: border-box;
      padding:15px;
      text-align: center;
    }
    .cont_wrap .p_pri .brsp{
      display: block;
    }
    .cont_wrap .submit{
      margin: 20px auto 0;
      padding-left: 18px;
    }
    .des{
      text-align: left;
    }
    .contact .cont_wrap .submit {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      min-width: 200px;
      widows: 50%;
    }
    .submitwrap{
      text-align: center;
    }
    .button.submit:hover input {
      color: #fff !important;
    }
  }
}