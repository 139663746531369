@charset "utf-8";
@import "_mixin.scss";
@import "_config.scss";

.works_mv{
  background:url(../img/mv_works.jpg) no-repeat center center;
  background-size: cover;
}
.works_wrap{
  padding:0 0 100px;
  @include media(767px) {
    padding:0 0 0px;
  }
}
.works_cat a{
  padding:0 20px 0px 18px;
  box-sizing: border-box;
  width: max-content;
  display: inline-block;
  font-size: 12px;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  position: relative;
  &:hover{
    color:$c2;
  }
  &:before{
    content: url(../img/pencil_hoso.svg);
    width: 14px;
    display: inline-block;
    height: auto;
    position: absolute;
    top:3px;
    left:0;
  }
}
.works_main{
  width:75%;
  @include media(959px) {
    width:100%;
    margin-bottom: 60px;
  }
  .s_works_head{
    .works_cat{
      text-align: center;
      &:before{
        display: block;
        content:"";
        background:$c7;
        width: 1px;
        height: 30px;
        margin: 10px auto 20px;
      }
      &:after{
        display: block;
        content:"";
        background:$c7;
        width: 1px;
        height: 30px;
        margin: 20px auto 20px;
      }
    }
    .works_ja_ttl{
      margin: 80px 0 5px;
      font-weight: bold;
      text-align: center;
      @include fz_24
        @include media(500px) {
          font-size:5vw;
      }
        }
    .works_eng_ttl{
      font-size: 12px;
      margin: 0 0 10px 0;
      letter-spacing: 3px;
      padding: 0 0 0 3px;
      box-sizing: border-box;
      text-align: center;
    }
  }
  .s_works_slider{
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  .blog_photo{
    margin:0 0 40px 0;
  }
  .works_para{
    margin:80px 0 40px 0;
    h3{
      font-weight: bold;
      margin:0 0 20px 0;
      @include fz_18
        }
    p{
      margin-bottom:15px;
      @include fz_14
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    strong,span{
      font-weight: bold;
    }
    a{
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
    a:hover{
      color:$c2;
    }
  }
  .works_sub{
    text-align: center;
    margin-bottom: 100px;
    @include media(767px) {
      margin-bottom: 80px;
    }
    dt{
      font-weight: bold;
      @include fz_18
    }
    dd{
      margin-bottom: 20px;
      @include fz_14
    }
    &:before{
      display: block;
      content:"";
      background:$c7;
      width: 1px;
      height: 30px;
      margin: 10px auto 20px;
    }
    &:after{
      display: block;
      content:"";
      background:$c7;
      width: 1px;
      height: 30px;
      margin: 20px auto 20px;
    }
  }
}
.s_works_rela{
  h4{
    font-weight: bold;
    margin-bottom:40px;
    @include fz_20
      }
  ul{
    @include fz_14
      li{
        width:48%;
        margin-bottom:60px;
        &:last-of-type,
        &:nth-of-type(3){
          margin-bottom: 0;
        }
        @include media(640px) {
          width:100%;
          &:last-of-type,
          &:nth-of-type(3){
            margin-bottom:60px;
          }
        }
    }
    a{
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
    a:hover{
      color:$c2;
    }
    .imgbox{
      margin:0 0 20px 0;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
    }
    .works_ja_ttl{
      margin: 0 auto 0px;
      text-align: center;
      font-weight: bold;
      @include fz_14
        }
    .works_eng_ttl{
      text-align: center;
      margin: 0 auto 20px;
      letter-spacing: 2px;
      font-weight: bold;
      line-height: 1;
      @include fz_20
        @include media(500px) {
          font-size:5vw;
      }
    }
    .works_cat{
      text-align: center;
    }
    .works_cat a:first-of-type{
      display: none;
    }
  }
}
.blog_side{
  width:20%;
  @include media(959px) {
    width:100%;
  }
  .side_box{
    border-top: 1px solid $c7;
    padding:40px 0 60px;
    h4{
      font-weight: bold;
      margin-bottom:20px;
      @include fz_20
        }
    ul{
      @include fz_14
        li{
          margin-bottom: 10px;
      }
      a{
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
      a:hover{
        color:$c2;
      }
    }
    .newlist{
      li{
        margin-bottom:40px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      .imgbox{
        margin:0 0 10px 0;
        img:hover{
          opacity: 0.8;
        }
      }
      h5{
        font-weight: bold;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        cursor: pointer;
        &:hover{
          color:$c2;
        }
      }
      .date{
        @include fz_10
          }
    }
  }
  .side_contact{
    p{
      @include fz_14
        }
    .btn_01{
      display: block;
      margin:30px 0 0;
      width: 80%;
      max-width: 200px;
      float:right;
    }
  }
}
.a_works_list{
  ul{
    @include fz_14
      li{
        width:48%;
        margin-bottom:60px;
        @include media(640px) {
          width:100%;
        }
    }
    a{
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
    a:hover{
      color:$c2;
    }
    .imgbox{
      margin:0 0 20px 0;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
      max-width: 800px;
    }
    .works_ja_ttl{
      margin: 0 auto 0px;
      text-align: center;
      font-weight: bold;
      @include fz_14
        }
    .works_eng_ttl{
      text-align: center;
      margin: 0 auto 20px;
      letter-spacing: 2px;
      font-weight: bold;
      line-height: 1;
      @include fz_20
        @include media(500px) {
          font-size:5vw;
      }
    }
    .works_cat{
      text-align: center;
    }
    .works_cat a:first-of-type{
      display: none;
    }
  }
}
.a_blog_cont{
  a{
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
  a:hover{
    color:$c2;
  }
}