@charset "utf-8";
@import "_mixin.scss";
@import "_config.scss";

.blog_mv{
  background:url(../img/mv_blog.jpg) no-repeat center center;
  background-size: cover;
}
.blog_wrap{
  padding:0 0 100px;
  @include media(767px) {
    padding:0 0 0px;
  }
}
.blog_main{
  width:75%;
  @include media(959px) {
    width:100%;
    margin-bottom: 60px;
  }
  .blog_cat{
    a{
      padding: 8px 15px;
      background: $c7;
      width: max-content;
      font-size: 12px;
      display: inline-block;
      margin-right: 10px;
      &:last-of-type{
        margin-right: 0px;
      }
    }
  }
  .blog_ttl{
    margin: 10px 0 5px;
    font-weight: bold;
    @include fz_24
  }
  .date{
    font-size: 12px;
    margin: 0 0 10px 0;
  }
  .blog_photo{
    margin:0 0 40px 0;
  }
  .blog_para{
    margin:0 0 60px 0;
    h3{
      font-weight: bold;
      margin:0 0 20px 0;
      border-left: 6px solid $c2;
      padding:0 0 5px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid $c7;
      @include fz_18
        }
    p{
      margin:0 0 15px 0;
      line-height: 2.2;
      span,
      strong{
        font-weight: bold;
      }
    }
    ul{
      margin:0 0 15px 0;
    }
    li{
      line-height: 2.5;
    }
    a{
      display: inline-block;
      text-decoration: underline;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      &:hover{
        color:$c2;
      }
    }
  }
  .snsshare{
    text-align: right;
    margin: 0 0 40px 0;
  }
}
.s_blog_rela{
  h4{
    font-weight: bold;
    margin-bottom:40px;
    @include fz_20
  }
  ul{
    @include fz_14
      li{
        width:30%;
    }
    a{
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
    a:hover{
      color:$c2;
    }
    .imgbox{
      margin:0 0 10px 0;
    }
    .blog_cat{
      a{
        margin:0 10px 10px 0;
        padding: 5px 10px;
        background: $c7;
        width: max-content;
        font-size: 10px;
        &:last-of-type{
          margin:0 0 10px 0;
        }
      }
    }
    h5{
      font-weight: bold;
    }
    .date{
      @include fz_10
        }
  }
}
.blog_side{
  width:20%;
  @include media(959px) {
    width:100%;
  }
  .side_box{
    border-top: 1px solid $c7;
    padding:40px 0 60px;
    h4{
      font-weight: bold;
      margin-bottom:20px;
      @include fz_20
    }
    ul{
      @include fz_14
        li{
          margin-bottom: 10px;
      }
        a{
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
      }
      a:hover{
        color:$c2;
      }
    }
    .newlist{
      li{
        margin-bottom:40px;
        &:last-of-type{
          margin-bottom: 0;
        }
        @include media(959px) {
          width:48%;
          &:last-of-type{
            margin-bottom:40px;
          }
        }
      }
      .blog_cat{
        a{
          margin:0 10px 10px 0;
          padding: 5px 10px;
          background: $c7;
          width: max-content;
          font-size: 10px;
          display: inline-block;
          &:last-of-type{
            margin:0 0px 10px 0;
          }
        }
      }
      .imgbox{
        margin:0 0 10px 0;
        img:hover{
          opacity: 0.8;
        }
      }
      h5{
        font-weight: bold;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        cursor: pointer;
        &:hover{
          color:$c2;
        }
      }
      .date{
        @include fz_10
          }
    }
  }
}
.a_blog_list{
  li{
    border-top:1px solid $c7;
    padding:60px 10px;
    @include fz_14
    &:first-of-type{
      border-top:none;
    }
    @include media(767px) {
      padding:40px 0;
    }
    h5{
      font-weight: bold;
      margin-bottom: 20px;
    }
    .date{
      margin:20px 0 0px 0;
      @include fz_10
        }
    .readmore{
      margin-top:10px;
      @include fz_10
    }
  }
  .c_l{
    width:30%;
    @include media(767px) {
      width:100%;
      margin-bottom:20px;
    }
  }
  .c_r{
    width: 65%;
    @include media(767px) {
      width:100%;
    }
  }
}
.a_blog_cont{
  a{
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
  a:hover{
    color:$c2;
  }
}