@charset "utf-8";
@import "_mixin.scss";
@import "_config.scss";

/*--------
--common parts
-----------------------------------------------*/

.ttl_01{
  border-bottom:3px solid $base_font_color;
  width: auto;
  padding-right: 30px;
  box-sizing: border-box;
  margin:0 auto 40px; 
  position: relative;
  font-weight: bold;
  @include fz_24
    }
.ttl_01:after{
  display: block;
  content:"";
  width: 80%;
  height: 1px;
  background: $base_font_color;
  position: absolute;
  top: 46px;
}
.ttl_02{
  margin:0 0 60px 7.5%;
  line-height: 1;
  @include media(767px) {
    margin:0 0 40px 1.5%;
    line-height: 1;
  }
  .eg_01{
    font-size:60px;
    font-weight: bold;
    float: left;
    letter-spacing: 3px;
    @include media(767px) {
      font-size:40px;
    }
  }
  .eg_02{
    color:$c2;
  }
  .ja_01{
    padding:25px 0 0 60px;
    box-sizing: border-box;
    float: left;
    @include fz_14
      @include media(767px) {
        padding:15px 0 0 20px;
    }
  }
}
.p_mv{
  width: 100%;
  height: 300px;
  position: relative;
  .page_ttl{
    position: absolute;
    bottom:-18px;
    left:100px;
    z-index: 2;
    font-weight: bold;
    @include fz_24
      @include media(767px) {
        left:0px;
        width: 100%;
        text-align: center;
    }
  }
}
.breadlist{
  .flex{
    justify-content: flex-start;
    padding: 100px 0 40px;
    @include media(767px) {
      padding: 60px 0 30px;
    }
    @include fz_12
      a{
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
    }
    a:hover{
      color:$c2;
    }
  }
}
.listbg {
  outline: none;
}
.listbg ::before,
.listbg ::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: '';
}
.listbg ,
.listbg ::before,
.listbg ::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.listbg  {
  position: relative;
  z-index: 2;
  background-color: #fff;
  overflow: hidden;
}
.listbg ::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(.5);
  transform: scale(.5);
}
.listbg :hover::after {
  background: $c3;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.p_bread{
  margin:0 0 0px 7.5%;
  @include media(767px) {
    margin:0 0 0px 1.5%;
  }
}
.btn_01{
  display: inline-block;
  width: 200px;
  height: 54px;
  text-align: center;
  text-decoration: none;
  line-height: 54px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
  color:#fff;

  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    display: block;
    content: '';
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
  }

  background-color: #333;
  color: #fff !important;
  &:hover {
    letter-spacing: 5px;
  }
}
/*.tri{
  width: 0;
  height: 0;
  border: 40px solid transparent;
  border-top: 40px solid $c2;  
}*/
.tri {
  width: 30px;
  height: 30px;
  border-left: 1px solid $c2;
  border-bottom: 1px solid $c2;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb 1.5s infinite;
  animation: sdb 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
.tri_min{
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid $c2;
  border-bottom: 1px solid $c2;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb 1.5s infinite;
  animation: sdb 1.5s infinite;
  box-sizing: border-box;
}
.p_subnav{
  width: 90%;
  margin: 0 auto;
  max-width: 960px;
  @include media(767px) {
    width: 100%;
  }
  a{
    display: block;
    width: calc(100% / 3);
    text-align: center;
    padding:20px 0;
    font-size: 12px;
    box-sizing: border-box;
    &:first-of-type,
    &:nth-of-type(2){
      border-right:1px solid $c5;
    }
    @include media(767px) {
      font-size: 10px;
    }
  }
  .engmenu{
    font-weight: bold;
    margin:0 auto 10px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    @include media(767px) {
      font-size: 14px;
    }
  }
  .tri_min{
    margin:0 auto 0;
  }
}

/*--------
--TOP
-----------------------------------------------*/

.top_info{
  padding:60px;
  box-sizing: border-box;
  width: 45%;
  height: 100vh;
  background: #fff;
  @include media(1200px) {
    font-size:12px;
    padding:20px;
  }
  @include media(1039px) {
    width: 60%;
  }
  @include media(767px) {
    background: transparent;
    width: 100%;
    height: 100vh;
    .eng{
      display: none;
    }
  }
  @include media(640px) {
    font-size:10px;
  }
  .top_logo{
    max-width: 340px;
    width: 50%;
    margin: 18% auto 5%;
    @include media(767px) {
      margin: 18vh auto 10%;
      max-width: 250px;
      width: 70%;
    }
  }
  .top_catch{
    text-align: center;
    margin: 0 auto;
    @include fz_16;
    @include media(767px) {
      display:none;
      /*background:rgba(255,255,255,0.8);
      padding:20px 10px;
      box-sizing: border-box;
      text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;*/
    }
  }
}
.top_catch_2{
  display:none;
  @include media(767px) {
    display:block;
    text-align: center;
    margin: 40px auto 0;
    @include fz_16;
    /*background:rgba(255,255,255,0.8);
    padding:20px 10px;
    box-sizing: border-box;
    text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;*/
  }
}
.top_bg{
  width:100%;
  height: 100vh;
  //background:url(../img/mv_top.jpg) no-repeat center center;
  //background-size:cover;
  position: fixed;
  z-index: 1;
  top:0;
  right:0;
  @include media(1039px) {
    width: 40%;
  }
  @include media(767px) {
    display: none;
    height: 60vh;
  }
}
.top_bgswipe{
  width:55%;
  height: 100vh;
  //background:url(../img/mv_top.jpg) no-repeat center center;
  background-size:cover;
  background-position: center center;
  position: fixed;
  z-index: 1;
  top:0;
  right:0;
  @include media(1039px) {
    width: 100%;
    height: 100vh;
  }
}
.header{
  @include media(767px) {
    padding: 60px 0 0;
    background: #fff;
  } 
}

.swipe_sp{
  @include media(767px) {
    width: 100%;
    height: 80vh;
    background:url(../img/mv_top_sp_new.jpg) no-repeat center 70%;
    background-size: cover;
  }
}
.topics_box{
  background:rgba(255,255,255,0.8);
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 80%;
  max-width: 800px;
  padding:60px;
  box-sizing: border-box;
  a{
    margin-bottom:10px;
    background:url(../img/arrow_02.svg) no-repeat right center;
    background-size:15px;
    padding-right:30px;
    box-sizing: border-box;
    @include fz_16
      &:last-of-type{
        margin-bottom: 0;
    }
  }
}
.topics_box.topmv{
  z-index: 3;
  width:52%;
  @include media(1200px) {
    display: none;
  }
}
.topics_box.topmvbot{
  display: none;
  position: static;
  top: 0;
  left: 0;
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  @include media(1200px) {
    display: block;
  }
  @include media(767px) {
    position: static;
    bottom: 0px;
    right: 0px;
    width: 100%;
    max-width: auto;
    padding:40px 5% 0;
    box-sizing: border-box;
  }
}
.topwrap{
  position: relative;
  z-index: 2;
  background:#fff;
  width: 100%;

  .cont_01{
    @include pad_100
      .c_l{
        width: 50%;
        @include media(1039px) {
          width: 100%;
          margin-bottom: 20px;
        }
    }
    .c_r{
      width: 47%;
      @include media(1039px) {
        width: 100%;
      }
      .copy{
        font-size:60px;
        font-weight: bold;
        text-decoration: underline;
        line-height: 1.2;
        @include media(1199px) {
          font-size:5vw;
        }
        @include media(1039px) {
          width: 90%;
          margin: 0 auto;
          font-size:9vw;
        }
      }
      h2{
        margin: 80px 100px 20px 40px;
        font-weight: bold;
        letter-spacing: 3px;
        @include fz_18
          @include media(1039px) {
            width: 90%;
            margin: 40px auto 20px;
        }
      }
      .text{
        margin:0px 100px 40px 40px;
        @include fz_14
        @include media(1039px) {
            width: 90%;
            margin: 0px auto 40px;
        }
          p{
            margin-bottom:15px;
            &:last-of-type{
              margin-bottom: 0;
            }
        }
      }
      .btn_01{
        margin-left: calc(100% - 300px);
        @include media(1039px) {
          margin: 0px 5% 0 0;
          display: block;
          float:right;
        }
      }
    }
  }
  .cont_02{
    padding:0 0 100px;
    @include media(1039px) {
      padding:0 0 80px;
    }
    @include media(767px) {
      padding:0 0 60px;
    }
    .c_l{
      width: 50%;
      @include media(1039px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .c_r{
      width: 47%;
      @include media(1039px) {
        width: 100%;
      }
      .copy{
        font-size:60px;
        font-weight: bold;
        text-decoration: underline;
        line-height: 1.2;
        margin-left: 100px;
        @include media(1199px) {
          font-size:5vw;
        }
        @include media(1039px) {
          width: 90%;
          margin: 0 auto;
          font-size:9vw;
        }
      }
      h2{
        margin: 80px 0px 20px 140px;
        font-weight: bold;
        letter-spacing: 3px;
        @include fz_18
        
          @include media(1039px) {
            width: 90%;
            margin: 40px auto 20px;
        }
      }
      .text{
        margin:0px 0px 40px 140px;
        @include fz_14
          @include media(1039px) {
            width: 90%;
            margin: 0px auto 40px;
        }
          p{
            margin-bottom:15px;
            &:last-of-type{
              margin-bottom: 0;
            }
            a{
              color:$c2;
              font-weight: bold;
              display: inline-block;
              transition-duration: 0.3s;
              transition-timing-function: ease-in-out;
              &:hover{
                opacity: 0.8;
              }
            }
        }
      }
      .btn_01{
        margin-left: calc(100% - 200px);
        @include media(1039px) {
          margin: 0px 5% 0 0;
          display: block;
          float:right;
        }
      }
    }
  }
  .t_blog_list{
    li{
      width: 30%;
      margin-bottom: 30px;
      @include fz_14
        @include media(1039px) {
          width: 48%;
      }
      @include media(500px) {
        width: 100%;
      }
      h3{
        font-weight: bold;
        margin: 0px 0 10px;
      }
      .imgbox{
        img:hover{
          opacity: 0.8;
        }
      }
      .date{
        margin:15px 10px 10px 0;
        float:left;
        @include fz_10
          }
      .readmore{
        margin-top:10px;
        @include fz_10
          }
      .blog_cat{
        a{
          float:left;
          margin:10px 10px 10px 0;
          padding: 5px 10px;
          background: $c7;
          width: max-content;
          font-size: 10px;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
          &:last-of-type{
            margin:10px 0px 10px 0;
          }
        }
        a:hover{
          color:$c2;
        }
      }
    }
  }
  .t_blog_cont{
    a{
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
    a:hover{
      color:$c2;
    }
  }
  .cont_03{
    padding:100px 0 0px;
    @include media(1040px) {
      padding:80px 0 0px;
    }
    @include media(767px) {
      padding:60px 0 0px;
    }
    .subttl{
      color:$c2;
      font-weight: bold;
      font-size:16px;
      margin-bottom:10px;
    }
    .subdesc{
      font-size:14px;
      margin-bottom:60px;
      @include media(767px) {
        margin-bottom:40px;
      }
    }
  }
  .cont_03_01{
    .btn_01{
      margin:20px 0 60px;
      float:right;
    }
  }
  .cont_03_02{
    margin-top:40px;
    background:url(../img/top_03.jpg) no-repeat center center;
    background-size:cover;
    background-attachment: fixed;
    @include pad_100
      @include media(767px) {
        background:url(../img/top_03.jpg) no-repeat center center;
        background-size:cover;
        background-attachment:inherit;
    }
      .cont_03_02_in{
        position: relative;
        z-index: 3;
    }
    li{
      width: 45%;
      background-color: rgba(255,255,255,0.9);
      padding: 60px;
      box-sizing: border-box;
      &:first-of-type{
        background-image:url(../img/top_04.png);
        background-repeat: no-repeat;
        background-size:contain;
        background-position: center center;
      }
      &:nth-of-type(2){
        background-image:url(../img/top_05.png);
        background-repeat: no-repeat;
        background-size:contain;
        background-position: center center;
      }
      @include media(767px) {
        width: 100%;
        margin-bottom: 20px;
        padding: 40px 10px;
        &:last-pf-type{
          margin-bottom: 0;
        }
      }
      .subdesc {
        margin-bottom: 40px;
      }
    }
    .btn_01{
      float:right;
    }
  }
  .guide_menulist{
    li{
      width:30%;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      padding:40px 20px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom:40px;
      @include media(1039px) {
        width: 48%;
        margin-bottom: 20px;
        &:last-pf-type,
        &:nth-pf-type(5){
          margin-bottom: 0;
        }
      }
      @include media(767px) {
        width: 100%;
        margin-bottom: 20px;
        padding: 40px 10px;
        &:last-pf-type{
          margin-bottom: 0;
        }
      }
      i{
        font-size:70px;
        opacity: 0.8;
      }
      img{
        height:80px;
        width: auto;
      }
      .ttl{        
        font-weight:bold;
        margin:20px auto;
        font-size:14px;
      }
      .desc{
        text-align: left;
        font-size:14px;
      }
      &:last-of-type{
        img{
          height: 70px;
          margin-top: 10px;
        }
      }
    }
  }
  .cont_04{
    background: $c6;
    @include pad_100
      .btn_01{
        float:right;
    }
      }
  .cont_05{
    @include pad_100
      .btn_01{
        float:right;
    }
  }
  .cont_06{
    margin-top:40px;
    background:url(../img/top_07.jpg) no-repeat center center;
    background-size:cover;
    background-attachment: fixed;
    @include pad_100
      @include media(767px) {
        background:url(../img/top_07_sp.jpg) no-repeat center center;
        background-size:cover;
        background-attachment:inherit;
    }
      .cont_06_in{
        position: relative;
        z-index: 3;
    }
    .ttl{
      letter-spacing: 5px;
      font-weight: bold;
      line-height: 1;
      margin:0 auto 60px;
      text-align: center;
      text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;
      @include fz_30
        @include media(600px) {
          font-size: 5vw;
      }
        }
    li{
      width: 30%;
      @include media(767px) {
        width: 100%; 
        margin: 0 auto 40px;
        &:last-of-type{
          margin: 0 auto 0px;
        }
      }
    }
    .listbox{
      background-color: rgba(255,255,255,0.9);
      padding: 60px;
      box-sizing: border-box;
      @include media(1300px) {
        padding: 60px 40px;
      }
      @include media(1100px) {
        padding: 60px 30px;
      }
      @include media(767px) {
        padding: 40px 10px;
        width: 100%; 
        margin: 0 auto 40px;
        &:last-of-type{
          margin: 0 auto 0px;
        }
      }
      .subttl{
        font-weight: bold;
        text-align: center;
        margin:20px auto;
        @include fz_14
          }
      .subdesc {
        @include fz_14
          }
      .icon{
        border-radius: 50%;
        max-width:200px;
        width: 80%;
        padding:30px;
        box-sizing: border-box;
        text-align: center;
        background:$c2;
        margin: 0 auto;
        @include media(767px) {
          max-width:200px;
          width:50%;
        }
      }
    }
    .btn_01{
      margin: 40px auto 0;
      display: block;
    }
  }
}
/*--------
--about
-----------------------------------------------*/

.about_mv{
  background:url(../img/mv_about.jpg) no-repeat center center;
  background-size: cover;
}
.about_wrap{
  .subttl{
    color:$c2;
    font-weight: bold;
    font-size:16px;
    margin-bottom:10px;
  }
  .subdesc{
    font-size:14px;
    @include media(767px) {
      font-size:12px;
    }
    a{
      display: inline-block;
    }
  }
  .cont_01{
    @include pad_100
    .cont_01_01{
      margin-bottom: 100px;
      @include media(767px) {
        margin-bottom: 60px;
      }
      .ttl{
        text-align: center;
        margin: 40px auto 80px;
        @include fz_40
          @include media(767px) {
            margin: 80px auto 80px;
        }
      }
      .text{
        text-align: center;
        line-height: 2;
        background:url(../img/about_01.png) no-repeat center center;
        background-size: contain;
        @include fz_14
          @include media(767px) {
            text-align: left;
            background:url(../img/about_01_sp.png) no-repeat center center;
            background-size:contain;
        }
          p{
            margin-bottom: 15px;
            &:last-of-type{
              margin-bottom: 0;
            }
            .bold{
              font-weight: bold;
            }
        }
        .m_t{
          margin-top: 40px;
        }
      }
    }
    .cont_01_02{
      .subttl{
        color:$c2;
        font-weight: bold;
        font-size:16px;
        margin-bottom:10px;
      }
      .subdesc{
        font-size:14px;
        margin-bottom:60px;
      }
      li{
        margin-bottom: 40px;
        &:last-pf-type{
          margin-bottom: 0;
        }
      }
      .c_l{
        width:30%;
        @include media(1039px) {
          width:80%;
          margin: 0 auto 10px;
        }
      }
      .c_r{
        width: 60%;
        @include media(1039px) {
          width:100%;
        }
        h4{
          font-weight: bold;
          letter-spacing: 3px;
          padding:20px 0 40px;
          @include fz_18
        }
        .text{
          @include fz_14
            p{
              margin-bottom: 15px;
              &:last-of-type{
                margin-bottom: 0;
              }
              .bold{
                font-weight: bold;
              }
              a{
                text-decoration: underline;
              }
          }
          .m_t{
            margin-top: 40px;
          }
        }
      }
    }
  }
  .cont_02{
    background: #f7f7f7;
    @include pad_100
      .c_l{
        width:40%;
        @include media(767px) {
          width:80%;
          margin: 0 auto 10px;
        }
    }
    .c_r{
      width: 47%;
      @include media(767px) {
        width:100%;
      }
    }
    .name{
      font-weight: bold;
      margin: 100px 0 40px;
      position: relative;
      @include media(767px) {
        margin: 40px 0 40px;
      }
      @include fz_24
        span{
          font-size:14px;
          padding-left:20px;
          box-sizing: border-box;
      }
      .pin {
        position: absolute;
        left: -11.1111111111vw;
        top: 50%;
        width: 11.1111111111vw;
        height: 1px;
        background: rgba(0,0,0,0.2);
        display: block;
        -webkit-transform: translateX(-33%);
        transform: translateX(-33%);
        @include media(767px) {
          display: none;
        }
      }
      .pin:before {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        background: #000;
        position: absolute;
        left: 0;
        top: -1px;
        border-radius: 50%;
        display: block;
      }
    }
    .pro_list_01{
      margin-top: 60px;
      
      @include fz_14
      .subttl{
        color: $c2;
        font-weight: bold;
        @include fz_24
      }
      .box{
        margin-bottom: 30px;
        &:last-of-type{
          margin-bottom: 0px;
        }
        .subdesc{
          line-height: 2;
          margin-bottom: 15px;
          &:last-of-type{
            margin-bottom: 0px;
          }
        }
        a{
          text-decoration: underline;
        }
        span{
          font-weight: bold;
        }
      }
    }
    .pro_list_02{
      @include fz_14
        dt{
          font-weight: bold;
          @include fz_16
            }
      dd{
        margin:5px 0 20px;
        &:last-of-type{
          margin: 5px 0 0;
        }
      }
    }
    .hide-text {
      display: none;
    }


    button.readmore {
      position: relative;
      height: 80px;
      width: 100px;
      margin: 20px 0 0 2.5%;
      display: block;
      background-color: transparent;
      color: #666;
      padding-bottom: 40px;
      border: none;
      outline: 0;
      transition: .5s;
      -erbkit-transition: .5s;
      cursor: pointer;
    }

    button.readmore::after {
      content: " ";
      position: absolute;
      width: 20px;
      height: 20px;
      border-top: solid 3px #666;
      border-right: solid 3px #666;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      right: 40px;
      top: 25px;
      transition: .5s;
      -erbkit-transition: .5s;
    }

    button.readmore:hover::after {
      top: 40px;
    }

    .on-click {
      color: transparent!important;
    }

    .on-click {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
    }
  }
  .cont_03{
    @include pad_100
      .team_wrap{
        margin-bottom:60px;
        &:last-of-type{
          margin-bottom:0px;
        }
        h3{
          width:80%;
          max-width: 400px;
          margin: 40px auto 0px;
        }
        .text{
          line-height: 2;
          text-align: center;
          width: 80%;
          margin: 0 auto 40px;
          @include fz_14
            @include media(767px) {
              text-align: left;
              width: 100%;
          }
        }
        .btn_01{
          margin: 0 auto 40px;
          display: block;
        }
        li{
          width: 25%;
          @include media(767px) {
            width: 50%;
          }
        }
    }
  }
}
/*--------
--guide
-----------------------------------------------*/

.guide_mv{
  background:url(../img/mv_guide.jpg) no-repeat center center;
  background-size: cover;
}
.guide_wrap{
  .subttl{
    color:$c2;
    font-weight: bold;
    font-size:16px;
    margin-bottom:10px;
  }
  .subdesc{
    font-size:14px;
    @include media(767px) {
      font-size:12px;
    }
    a{
      display: inline-block;
    }
  }
  .cont_01{
    @include pad_100
      }
  .cont_02{
    background: #f7f7f7;
    @include pad_100
      }
  .cont_03{
    @include pad_100
      }
  .p_guide_menulist{
    margin-top:60px;
    @include media(767px) {
      margin-top:40px;
    }
    .list{
      width:47%;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      padding:40px 20px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom:40px;
      @include media(767px) {
        width:100%;
      }
      i{
        font-size:70px;
        opacity: 0.8;
      }
      img{
        height:80px;
        width: auto;
      }
      .ttl{        
        font-weight:bold;
        margin:20px auto;
        font-size:14px;
        color:$c2;
      }
      .desc{
        text-align: left;
        font-size:14px;
      }
      &:last-of-type{
        img{
          height: 70px;
          margin-top: 10px;
        }
      }
      .point{        
        font-weight:bold;
        margin:20px auto 15px;
        font-size:14px;
        text-align: left;
        border-top:1px solid $c1;
        padding-top: 20px;
      }
      .list_check{
        font-size:14px;
        text-align: left;
        i{
          opacity: 0.7;
          font-size:13px;
        }
        li{
          margin-bottom:15px;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .p_guide_free{
    .ttl{
      letter-spacing: 5px;
      font-weight: bold;
      line-height: 1;
      margin:0 auto 60px;
      text-align: center;
      text-shadow: 0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff,0 0 1px #fff;
      @include fz_30
        }
    li{
      width: 30%;
      background-color: rgba(255,255,255,0.9);
      padding: 60px;
      box-sizing: border-box;
      .subttl{
        font-weight: bold;
        text-align: center;
        margin:20px auto;
        @include fz_14
          }
      .subdesc {
        @include fz_14
          }
      .icon{
        border-radius: 50%;
        width:200px;
        padding:30px;
        box-sizing: border-box;
        text-align: center;
        background:$c2;
        margin: 0 auto;
      }
    }
  }
  .p_guide_flowlist{
    margin-top:60px;
    .engttl{
      @include fz_10
        }
    .c_l{
      width:20%; 
      border-top:6px solid $c2;
      @include media(767px) {
        width:100%;
        margin-bottom: 25px;
      }
      .engttl{
        color:$c2;
        margin:10px 0 20px;
        @include fz_10
          @include media(767px) {
            margin:10px 0 10px;
        }
          }
      .num{
        font-size: 50px;
        font-weight: bold;
        line-height: 1;
        @include media(767px) {
          font-size: 35px;
        }
      }
    }
    .c_r{
      width: 80%;
      border-top:6px solid $c1;
      @include media(767px) {
        width:100%;
      }
      .engttl{
        margin:10px 0 25px;
        @include fz_10
          }
      .ttl{
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 25px;
        @include media(767px) {
          font-size: 16px;
        }
      }
      .textarea{
        p{
          margin-bottom: 15px;
          font-size: 14px;
        }
      }
      .img{
        margin-top:25px;
        height: 300px;
        width: 100%;
        @include media(767px) {
          height: 200px;
        }
      }
      .flow_01{
        background: url(../img/flow_01.jpg) no-repeat center center;
        background-size: cover;
      }
      .flow_02{
        background: url(../img/flow_02.jpg) no-repeat center center;
        background-size: cover;
      }
      .flow_03{
        background: url(../img/flow_03.jpg) no-repeat center center;
        background-size: cover;
      }
      .flow_04{
        background: url(../img/flow_04.jpg) no-repeat center center;
        background-size: cover;
      }
      .flow_05{
        background: url(../img/flow_05.jpg) no-repeat center center;
        background-size: cover;
      }
    }
    .tri{
      margin:40px auto 80px;
      display: block;
    }
  }
  .cont_03{
    .accordion{
      width: 90%;
      margin: 60px auto 0px;
      @include media(767px) {
        width: 100%;
        margin: 40px auto 0px;
      }
      .switch {
        padding: 20px 40px 20px 20px;
      }
      h4{
        font-weight: bold;
        font-size: 14px;
        margin-bottom:15px;
      }
      .pricedesc{
        font-size: 14px;
        margin-bottom:15px;
      }
      .price{
        font-size: 14px;
        margin-bottom:15px;
      }
      .price_2{
        font-size: 14px;
        font-weight: bold;
      }
      .pricedesc_2{
        font-size: 14px;
        margin-bottom:30px;
        font-weight: bold;
      }
    }
  }
}
/*--------
--contact
-----------------------------------------------*/

.contact_mv{
  background:url(../img/mv_contact.jpg) no-repeat center center;
  background-size: cover;
}
/*--------
--er
-----------------------------------------------*/

.er_mv{
  background:url(../img/mv_404.jpg) no-repeat center center;
  background-size: cover;
}
.er{
  .sitemap{
    @include pad_60
      a{
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
    }
    a:hover{
      color:$c2;
    }
    .big{
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 4px;
      line-height: 2;
    }
    .min{
      font-size: 14px;
      line-height: 2;
    }
    ul{
      width: 50%;
      margin-bottom: 40px;
      @include media(767px) {
        width: 100%;
      }
    }
  }
}